import axios from "axios";

const libraryMutations = {
    setFormItems(state, payload) {
        const name = payload.name;
        const value = payload.value;
        state.formItems[name] = value;
    },
    setApiItems(state, payload) {
        const name = payload.name;
        const value = payload.value;
        state.apiItems[name] = value;
    },
    setApiItemsList(state, payload) {
        payload.forEach(item => {
            const name = item.name;
            const value = item.value;
            state.apiItems[name] = value;
        });
    },
    setTabs(state, payload) {
        state.tabs = payload;
    },
}
const libraryActions = {
    updateFormItems(store, formItem) {
        store.commit('setFormItems', formItem);
    },
    updateApiItems(store, formLabel) {
        store.commit('setApiItems', formLabel);
    },
    updateApiItemsList(store, formItem) {
        store.commit('setApiItemsList', formItem);
    },
    async retrieveFolderDetail(store, folderId) {
        const response = await axios.get(`/api/library/folder/${folderId}`)
            .catch(e => {
                console.error("Error: " + e)
            })
        if (response && response.status === 200) {
            for (const property in response.data) {
                store.commit('setApiItems', {"name": property, "value": response.data[property]});
            }
        }
    },
    async upsertFolder(store, folderId) {
        // 更新・新規作成（2パターン（コピー時の新規作成 or 新規作成）で後処理が異なるため）
        return axios.post('/ajax/shelf-items/folder/edit', {
            folderId: folderId,
            folderName: store.state.formItems.library_folder_name,
            folderDescription: store.state.formItems.description,
            folderShareStatus: store.state.formItems.share_status,
            nicknameToStore: store.state.formItems.owner.owner_alias_name,
        })
    },
    async retrieveFolders(store) {
        const response = await axios.get(`/ajax/shelf-items/folder/getlist`)
            .catch(e => {
                console.error("Error: " + e)
            })
        if (response && response.status === 200 && response.data.folderList) {
            store.commit('setApiItems', {"name": 'folderList', "value": response.data.folderList});
        }
    },
}
const libraryGetters = {
    getFormItems: (state) => {
        return state.formItems;
    },
    getApiItems: (state) => {
        return state.apiItems;
    },
    isApiShared: (state) => {
        return state.apiItems.share_status === "shared"
    },
    isFormShared: (state) => {
        return state.formItems.share_status === "shared"
    },
    isForceUnshared: (state) => {
        return state.apiItems.share_status === "forceUnshared"
    },
    getTabs: (state) => {
        return state.tabs;
    }
}

const defaultLibrary = () => {
    return {
        formItems: {
            folderId: null,
            description: null,
            editable: null,
            library_folder_name: null,
            library_items_count: null,
            share_status: null,
            owner: {
                owner_alias_name: null,
            }
            //  要精査
        },
        apiItems: {
            folderId: null,
            description: null,
            editable: false,
            library_folder_name: null,
            library_items_count: null,
            share_status: null,
            owned_by_me: null,
            owner: {
                owner_alias_name: "",
                library_items_total: null,
                library_root_url_path: ""

            },
            folderList: [{
                folder_id: null,
                folder_name: null,
                shelf_items_count: null,
            }],
            selectedCopyFolderId: null,
        },
        tabs: null
    }
}

const moduleLibrary = {
    namespaced: true,
    state: defaultLibrary(),
    mutations: libraryMutations,
    actions: libraryActions,
    getters: libraryGetters
}

export {moduleLibrary};