<template>
  <v-container>
    <v-row>
      <v-col cols="12" :class="$vuetify.breakpoint.smAndDown? 'py-0': 'pt-4 pb-1'" class="font-weight-black text-caption">
        名前
      </v-col>
      <v-col cols="12" class="py-0">
        <validation-provider
            v-slot="{ errors, invalid }"
            name="フォルダ名"
            rules="required|max:30"
        >
        <v-text-field
            :disabled="!formItemsGetter.editable"
            outlined
            single-line
            :error-messages="errors"
            class="py-0"
            height="44px"
            v-model="libraryFolderName"
            label="フォルダ名（30文字まで）"
            counter="30"
        ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" :class="$vuetify.breakpoint.smAndDown? 'py-0': 'pt-4 pb-2'" class="font-weight-black text-caption">
        説明文
      </v-col>
      <v-col cols="12" class="py-0">
        <validation-provider
            v-slot="{ errors }"
            name="説明文"
            rules="max:500"
        >
          <v-textarea
              outlined
              single-line
              :error-messages="errors"
              v-model="description"
              counter="500"
              label="フォルダの説明文（500文字まで）"
              class="text-body-1"
          ></v-textarea>
        </validation-provider>
      </v-col>
      <v-col cols="12" :class="$vuetify.breakpoint.smAndDown? 'py-0': 'pt-4 pb-1'" class="font-weight-black text-caption">
        公開設定
      </v-col>
      <v-col cols="12" class="py-0">
        <v-checkbox
            :disabled="this.formItemsGetter.is_force_unshared"
            v-model="isShareStatus"
            type="checkbox"
            dense
            height="auto"
            name="公開設定"
            :ripple="false"
            :class="{'checkbox' : $vuetify.breakpoint.mdAndUp}"
            class="py-0"
            hide-details="auto"
        >
          <template #label>
            <div class="text-body-2 grey--text" :class="formItemsGetter.is_force_unshared ? 'text--darken-1' : 'text--darken-4'">公開モードにする
              <span v-if="formItemsGetter.is_force_unshared">- 変更できません</span>
            </div>

          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// ライブラリ関連のダイアログのフォームに責務を持つ
export default {
  name: "folder-preference-form",
  props: {
    formItemsSetter: {
      type: Function,
      required: false
    },
    formItemsGetter: {
      type: Object,
      required: false,
      default: () => ({
        folderId: null,
        library_folder_name: null,
        share_status: false,
        description: null,
        editable: true,
        library_items_count: null,
        is_force_unshared: false,
      }),
    },
  },
  data() {
    return {
      dialog: this.show,
      folderShareStatus: null,
      folderName: null,
      folderDescription: null,
      isForceUnshared: false
    }
  },
  created: function () {
    for (const property in this.formItemsGetter) {
      this.formItemsSetter( {"name": property, "value": this.formItemsGetter[property] });
    }
    this.libraryFolderName = this.formItemsGetter.library_folder_name;
    this.description = this.formItemsGetter.description;
  },
  computed: {
    isShareStatus: {
      get() {
        return this.formItemsGetter.share_status
      },
      set(event) {
        this.formItemsSetter({name: 'share_status', value: event})
      }
    },
    libraryFolderName: {
      get() {
        return this.folderName
      },
      set(event) {
        this.formItemsSetter({name: 'library_folder_name', value: event})
        this.folderName = event
      }
    },
    description:{
      get() {
        return this.folderDescription
      },
      set(event) {
        this.formItemsSetter({name: 'description', value: event})
        this.folderDescription = event
      }
    },
  },
}
</script>

<style lang="scss" scoped>

@import "vue_src/sass/vuetify_variables";
::v-deep {
  .v-input {
    input {
      background-color: transparent;
      border: 0 none;
    }
  }
  .checkbox {
    .v-icon {
      font-size: 28px;
    }
  }
  textarea {
    font-size: inherit;
    border: none;
  }
}
</style>