<template>
  <v-card class="pa-0 pa-md-9">
    <v-card-title>
      <v-layout class="darken-4 d-flex text-subtitle-1 font-weight-black" :class="!$vuetify.breakpoint.smAndDown ?'justify-center text-center mx-auto': 'ml-3'">
        <slot name="title"/>
      </v-layout>
      <v-btn
          :icon="!$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.smAndDown"
          class="ml-auto"
          x-large
          :class="!$vuetify.breakpoint.smAndDown && 'mt-n16 mr-n12'"
          @click="showDialog = false"
      >
        <template v-if="$vuetify.breakpoint.smAndDown">
          閉じる
        </template>
        <template v-else>
          <v-icon>mdi-close</v-icon>
        </template>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <slot name="content"/>
    </v-card-text>
    <v-divider></v-divider>
    <v-spacer></v-spacer>
    <v-card-actions>
      <slot name="action"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
// ライブラリ関連のダイアログのレイアウトに責務を持つ
export default {
  name: "folder-preference-layout",
  props:{
    value: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
}
</script>

<style lang="scss" scoped>

@import "vue_src/sass/vuetify_variables";
::v-deep {
  .v-input {
    input {
      background-color: transparent;
      border: 0 none;
    }
  }
  .checkbox {
    .v-icon {
      font-size: 28px;
    }
  }
  textarea {
    font-size: inherit;
    border: none;
  }
}
</style>