<template>
  <validation-observer ref="observer" v-slot="observerProps">
    <v-dialog
        v-model="showDialog"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :width="$vuetify.breakpoint.smAndDown? '100%': '520px'"
        style="z-index: 10000"
    >
        <folder-preference-layout
            v-model="showDialog"
        >
          <template #title>新規フォルダを追加</template>
          <template #content>
            <folder-preference-form
                :form-items-setter="setFormItems"
            >
            </folder-preference-form>
          </template>
          <template #action>
            <v-sheet width="100%">
              <v-row>
                <v-col col="6" class="pb-0">
                  <v-btn block outlined class="py-5" @click="showDialog = false">キャンセル</v-btn>
                </v-col>
                <v-col col="6" class="pb-0">
                  <v-btn :disabled="observerProps.invalid || buttonDisabled" block color="grey darken-4" class="py-5 white--text" @click="createFolder">OK</v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </template>
        </folder-preference-layout>
    </v-dialog>
  </validation-observer>
</template>

<script>
/// ダイアログの表示、アクション・ラベル文言などに責務をもつ ダイアログの表示やボタンのアクション定義など
import {mapActions, mapGetters} from "vuex";
import FolderPreferenceLayout from "../../molecules/library/folder-preference-layout.vue";
import FolderPreferenceForm from "../../molecules/library/folder-preference-form.vue";


export default {
  name: "create-folder",
  components: {
    FolderPreferenceForm,
    FolderPreferenceLayout,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    isFolderSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      buttonDisabled: false,
    }
  },
  computed: {
    ...mapGetters(
        'library', ['getApiItems', 'isApiShared']
    ),
    showDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    ...mapActions({
      setFormItems: 'library/updateFormItems',
      updateApiItems: 'library/updateApiItems',
      upsertFolder: 'library/upsertFolder',
      retrieveFolderDetail: 'library/retrieveFolderDetail',
      retrieveFolders: 'library/retrieveFolders',
    }),
    async createFolder(){
      this.buttonDisabled = true;
      const response = await this.upsertFolder(null).catch(e => {
        console.error("Error: " + e)
      })
      if (response && response.status === 200 && response.data.status) {
        if(this.isFolderSelect){
          await this.retrieveFolders();
        }
        // 更新したフォルダの情報を取得する
        await this.retrieveFolderDetail(this.getApiItems.folderId);
        // フォームを初期化する
        this.updateApiItems({name: 'selectedCopyFolderId', value: response.data.folderId});
        // スナックバーを表示
        this.$emit("copyFolderItems");
        this.buttonDisabled = false;
      } else {
        this.$emit("showSnackbar", "error");
        this.buttonDisabled = false;
      }
      this.showDialog = false;
    },
  },
}
</script>

<style scoped>

</style>